const initialValues = {
    status: "RASCUNHO",
    version: "1.0.1",
    registration: {  
        vendemmiaOperationUnit: null,
        operationType: [],
        companyName: "",
        companyFantasyName:"",
        companyOperationDocumentNumber:"",
        companyOperationSubscriptionState:"",
        companyBranches: [
            {
                companyOperationDocumentNumber:"",
                subscriptionState: "",
            }
        ],
    },
    contact: {
        commercial: [],
        operation: [],
        financial: [],
        legal: [],
    },

    commercial: {
        generalRestrictionsInformation: "",
        profitabilityInformation: "",
        contractValidUntil: "",
        annualBilling: "",
        additionalInformation: "",
    },

    tributary: {
        isIcmsTaxpayer: "",
        operationPurpose: "",
        fiscalBenefit: "",
        taxRegime: "",
        additionalInformation:"",
    },
    operational: {
        forwardingAgent: "",
        dispatchValue: "",
        productTypeDescription: "",
        isAntidumping: "",
        importingLicenseConsentingEntity: [],
        importingLicenseValue: "",
        importingLicenseAdditionalInformation: "",
        operationNature: "",
        incoterm: "",
        incotermAdditionalInformation: "",
        hasInternationalTransportQuote: "",
        transportModality: [],
        goodsEntranceFederativeUnit: "",
        goodsEntranceFederativeAdditionalInformation: "",
        customsClearanceLocation: [],
        customsClearanceLocationAdditionalInformation: "",
        insuranceCalculationBasisVendemmia: "",
        insuranceCalculationBasisClient: "",
        insuranceAdditionalInformation: "",
        storageAfterBilling: "",
        storageAfterBillingAdditionalInformation: "",
        nationalFreightModality: "",
        nationalFreightBilling: "",
        nationalFreightAdditionalInformation: "",
        nmcRelation: [], //TODO Esse campo ainda vai existir?
    },
    financial: {
        upFrontPaymentWarranty: "",
        upFrontPaymentWarrantyAdditionalInformation: "",
        commercialDiscount: "",
        icmsRate: "",
        discountValue: "",
        commercialDiscountAdditionalInformation: "",
        serviceInvoice: "",
        administrateTax: "",
        serviceInvoiceMinValue: "",
        serviceInvoiceModality: "",
        serviceInvoiceAdditionalInformation: "",
        exporterPayment: [],
        exporterPaymentAdditionalInformation: "",
        financialCharges: "",
        financialChargesModality: "",
        financialChargesAddition: "",
        financialChargesAdditionalInformation: "",
        paymentTermsCommodity: "",
        paymentTermsTax: "",
        paymentTermsExpenses: "",
        paymentTermsServiceInvoice: "",
        paymentTermsAdditionalInformation: ""
    }
};

export default initialValues;